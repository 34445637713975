const axios = require('axios');
import {config} from '@/assets/js/Utils.js';

export function getIECOrders(callback){
    let path = "/basket/get/IEC"

    axios.get(config.WS_URL + path).then((response) => {
        if(response.status === 200){
            callback(response.data)
        }
    })
}

export function getIECOrdersBydate(date, callback){
    let path = "/basket/get/IEC?date=" + date

    axios.get(config.WS_URL + path).then((response) => {
        if(response.status === 200){
            callback(response.data)
        }
    })
}

export function getIECOrdersByEmail(email, callback){
    let path = "/basket/get/IEC/by/email?email=" + email

    axios.get(config.WS_URL + path).then((response) => {
        if(response.status === 200){
            callback(response.data)
        }
    })
}

export function get(complexId, callback){
    let path = "/basket/get/" + complexId

    axios.get(config.WS_URL + path).then((response) => {
        if(response.status === 200){
            callback(response.data)
        }
    })
}

export function changeSubState(complexId, newSubState, callback){
    let path = "/basket/substate/change"

    const params = new URLSearchParams();
    params.append("complexId", complexId)
    params.append("subState", newSubState)

    axios.post(config.WS_URL + path, params).then((response) => {
        if(response.status === 200){
            callback(response.data)
        }
    })
}
