import {isNotNull} from "@/assets/js/Utils";
var mqtt = require('mqtt');

var client;

var mqttConf = {
    host: "mqtt.labs.worldline-solutions.com",
    port: 8003,
    username: "rd",
    password: "@t0s@t0s",
    rejectUnauthorized: false,
    protocol: 'wss'
}
var url = mqttConf.protocol + "://" + mqttConf.host + ":" + mqttConf.port

export var topic = {
    ordersRefresh: "rd/chatbot/connectedKitchenBot/IEC/orders/refresh",
    openLocker: "rd/locker/{lockerLocation}/{lockerID}/open",
    statusLocker: "rd/locker/{lockerLocation}/{lockerID}/status"
}


export function publishMessage(topic, message = "", callback = null) {
    client = mqtt.connect(url, mqttConf);

    errorHandler(client);

    client.on('connect', function () {
        if(isNotNull(topic)){
            if(isNotNull(callback)){
                client.publish(topic, message, {qos: 2}, callback);
            }else{
                client.publish(topic, message, {qos: 2});
            }
            console.log("MQTT publish on : " + topic)
            console.log("MQTT message : " + message)
        }
        topic = null
        message = null
    });
}

export function subscribeOrdersRefresh(callback = null) {
    client = mqtt.connect(url, mqttConf);

    errorHandler(client);

    client.subscribe(topic.ordersRefresh)

    client.on('message', function (topic, message) {
        console.log("MQTT receive on : " + topic)
        console.log("MQTT message : " + message)
        if(isNotNull(callback)){
            callback(message.toString())
        }
    })
}

export function unsubscribeOrdersRefresh() {
    client = mqtt.connect(url, mqttConf);

    errorHandler(client);

    client.unsubscribe(topic.ordersRefresh)
}

export function subscribeLocker(lockerLocation, lockerID, callback = null) {
    client = mqtt.connect(url, mqttConf);

    errorHandler(client);

    console.log("MQTT subscribe on : " + topic.statusLocker.replace("{lockerLocation}", lockerLocation).replace("{lockerID}", lockerID))
    client.subscribe(topic.statusLocker.replace("{lockerLocation}", lockerLocation).replace("{lockerID}", lockerID))

    client.on('message', function (topic, message) {
        console.log("MQTT receive on : " + topic)
        console.log("MQTT message : " + message)
        if(isNotNull(callback)){
            callback(message.toString())
        }
    })
}

export function unsubscribeLocker(lockerLocation, lockerID) {
    client = mqtt.connect(url, mqttConf);

    errorHandler(client);

    client.subscribe(topic.statusLocker.replace("{lockerLocation}", lockerLocation).replace("{lockerID}", lockerID))
}

function errorHandler(client){
    client.stream.on('error', function (err) {
        if(err){
            client.end(true);
            console.log("MQTT Error : " + err)
        }
    });
}
